import {
  CorvidSDKFactory,
  ICorvidModel,
  CorvidSDKModule,
} from '@wix/editor-elements-types';
import { importAll } from './build-utils';
import fallbackSDK from './core/corvid/Fallback/entry.corvid';

// this package is imported here once, so it won't be bundled into every SDK
import '@wix/editor-elements-corvid-utils';

function createSdkLoader(
  componentEntries: Array<ICorvidModel>,
): Record<string, () => Promise<CorvidSDKModule>> {
  return componentEntries.reduce<
    Record<string, () => Promise<CorvidSDKModule>>
  >((loader, entry) => {
    const { componentType, loadSDK } = entry;
    if (!componentType || !loadSDK) {
      throw new Error(
        'Error generating Corvid SDK loader! Corvid SDK entry (ComponentName/corvid/entry.corvid.ts) must be of type `ICorvidModel`',
      );
    }
    loader[componentType] = loadSDK;
    return loader;
  }, {});
}

const componentEntriesContext = require.context(
  './components',
  true,
  /entry\.corvid\.ts$/,
);

const coreComponentsEntriesContext = require.context(
  './thunderbolt-core-components',
  true,
  /entry\.corvid\.ts$/,
);

const componentEntries = importAll<ICorvidModel>(componentEntriesContext);
const coreComponentsEntries = importAll<ICorvidModel>(
  coreComponentsEntriesContext,
);

const sdkLoaders = createSdkLoader([
  ...coreComponentsEntries,
  ...componentEntries,
]);

export const loadComponentSdks = async (
  componentsTypes: Array<string> = [],
) => {
  const loaders = componentsTypes.map(componentType =>
    sdkLoaders[componentType]
      ? sdkLoaders[componentType]()
      : fallbackSDK.loadSDK(),
  );
  const loadedSDKs = await Promise.all(loaders);

  return loadedSDKs.reduce((acc, currentValue, index) => {
    acc[componentsTypes[index]] = currentValue ? currentValue.sdk : null;
    return acc;
  }, {} as Record<string, CorvidSDKFactory | null>);
};
