import { IMediaContainerCorvidModel } from '../../MediaContainer/MediaContainer.types';

const entry: IMediaContainerCorvidModel = {
  componentType: 'Column',
  loadSDK: () =>
    import(
      '../../MediaContainer/corvid/MediaContainer.corvid' /* webpackChunkName: "Column.corvid" */
    ),
};

export default entry;
